import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./components/routing/routers/MainRouter";
import "./assets/css/style.css";

function App() {
    return (
        <Router>
            <MainRouter />
        </Router>
    );
}

export default App;
