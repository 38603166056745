import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function ComingSoon() {
  return (
    <MainContainer>
      <WrapperBox>
        <ContentBox>
          <LogoBox to="/">
            <img
              src={require("../../assets/images/lebut.svg").default}
              alt="logo"
            />
          </LogoBox>
          <SiteUrl href="/" target="_blank">
            www.lebut.ai{" "}
          </SiteUrl>
          <SubHeading>Coming Soon</SubHeading>
        </ContentBox>
      </WrapperBox>
    </MainContainer>
  );
}

export default ComingSoon;

const MainContainer = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperBox = styled.section``;
const ContentBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoBox = styled(Link)`
  cursor: pointer;
  margin-bottom: 20px;
  width: 150px;
  @media all and (max-width: 480px) {
    font-size: 140px;
  }
`;
const SiteUrl = styled.a`
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #d8af4a;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  padding: 8px 40px;
  font-size: 20px;
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`;
const SubHeading = styled.h3`
  color: #cdb883;
  font-size: 55px;
  @media all and (max-width: 480px) {
    font-size: 45px;
  }
`;
